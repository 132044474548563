import styles from 'components/StickyBar/StickyBar.modules.scss';
import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';

import * as React from 'react';
import classNames from 'classnames';

const StickyBar = React.forwardRef(({ children, hasShadow = true }, ref) => {
  return (
    <div
      className={classNames(styles.container, hasShadow ? styles.shadow : null)}
      ref={ref}
    >
      <Container className={styles.wrapper}> {children}</Container>
    </div>
  );
});

export default StickyBar;
